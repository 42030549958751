/* purgecss ignore */
.jwsdw-pageWrapper {
  max-width: none;
}

.landingpage-wrapper > section {
  position: relative;
  line-height: 0;
}
.landingpage-wrapper .jws-grid-container-gutter:not(.jwsdw-lottery-mainform) {
  margin-bottom: 0;
}
.landingpage-wrapper .jws-grid-container-gutter:not(.jwsdw-lottery-mainform) > [class*=jws-grid-] {
  margin-bottom: 0;
}

picture {
  display: block;
  position: relative;
  line-height: 0;
}

figure {
  margin: 0;
}

.msdw-container {
  max-width: 1480px;
  margin-right: auto;
  margin-left: auto;
}

.msdw-contentBar-close {
  all: unset;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 14px;
  line-height: 0;
}
@media screen and (min-width: 900px) {
  .msdw-contentBar-close {
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    padding: 0px;
  }
}
.msdw-contentBar-close svg {
  width: 14px;
  height: 14px;
  pointer-events: none;
}/*# sourceMappingURL=20241206ContentBar_styles.css.map */